<template>
  <div v-if="showLoadingPage">
    <div class="text-div">
      <v-row
        class="fill-height"
        align="center"
        justify="center"
      >
        <p class="loader-text">
          {{ loadingMessage }}
        </p>
      </v-row>
    </div>
    <div class="loading-1">
      <i />
      <i />
      <i />
      <i />
      <i />
    </div>
  </div>
</template>
<script>

export default {
  name: 'LoadingPage',
  props: {
    showLoadingPage: {
      type: Boolean,
      default: false,
    },
    loadingMessage: {
      type: String,
      default: '',
    },
  },
};
</script>
<style scoped>
.text-div {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}
.loader-text {
  color: #37474F;
  font-family: 'Times New Roman', Times, serif;
  font-size: 25px;
  letter-spacing: 5px;
  text-align: center;
  margin: auto;
  font-weight: 700;
}
.loading-1 {
    position: absolute;
    width: 100px;
    height: 80px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
}
.loading-1 i {
    position: absolute;
    bottom: 0px;
    display: block;
    width: 9px;
    height: 5px;
    animation: loading-1 1.5s  infinite ease-in-out;
}
.loading-1 i:nth-child(2){
  left: 11px;
  animation-delay: .2s;
}
.loading-1 i:nth-child(3){
  left:22px;
  animation-delay: .4s;
}
.loading-1 i:nth-child(4){
  left:33px;
  animation-delay: .6s;
}
.loading-1 i:nth-child(5){
  left:44px;
  animation-delay: .8s;
}
@keyframes loading-1 {
  0% {
  height:5px;transform:translateY(0px);background:#B0BEC5;
  }
    25% {height:30px;transform:translateY(15px);background:#FF3700;}
    50% {height:5px;transform:translateY(0px);background:#B0BEC5;}
    100% {height:5px;transform:translateY(0px);background:#FF3700;}
}
</style>
